import React from 'react';
import Button from './reusable/Button';
import staff from '../assets/chi_siamo_images/staff_verde4.jpg';

const WeAreBlock = props => {
    return (
        <div className="weare">
            <div className="leftImage">
                <img alt="staff_costenaro" src={staff} className="weare_img img_resp" />
            </div>
            <div className="right_desctiption">
                <h3 className="opSans">Chi siamo</h3>
                <p className="opSans" style={{marginBottom: '2.5rem'}}>
                    Mi chiamo Giancarlo Costenaro e vivo a Vertemate con Minoprio, un piccolo paese situato tra la zona prealpina del lago di Como e l’inizio della pianura Padana.
                    Sono il contitolare dell’azienda agricola “Apicoltura Costenaro G&C SNC”. È una società a gestione famigliare nella quale sono coinvolte tre diverse generazioni; tutt’oggi i miei figli ed io lavoriamo con i miei genitori traendo dalla loro esperienza decennale iniziata nel 1974 per trarne ispirazioni per il futuro...
                </p>
                <Button to="/chisiamo" text="scopri di più" />
            </div>
        </div> 
    );
}

export default WeAreBlock;