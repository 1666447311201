import React from 'react';
import {ParallaxProvider, ParallaxBanner} from 'react-scroll-parallax';
import bckg from '../../assets/chi_siamo_images/cassetta-miele.jpg';

const ContattiHeader = () => {
    return (
        <ParallaxProvider>
            <ParallaxBanner
                className="contatti_header"
                layers={
                    [
                        {
                            image: bckg,
                            amount: -0.3,
                        }
                    ]
                }
                style={{
                    height: '40vh',
                }}
            > 
                <h1 className="contatti_title poppins">CONTATTACI</h1>
            </ParallaxBanner>
        </ParallaxProvider>
    );
}

export default ContattiHeader;