import React, { useState, useRef } from 'react';
import Carousel from 'nuka-carousel';

const ProductSlider = ({ pictures }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [navIndex, setNavIndex] = useState(0);
    let carousel;
    let nav;
    const handleLoadSize = car => {
        if (car === 'carousel') carousel.setDimensions();
        else nav.setDimensions();
    }
    return (
        <div className="slider_and_nav">
            <div className="navbar_slider">
                <Carousel
                    ref={c => nav = c}
                    slideIndex= { navIndex }
                    afterSlide={ idx => {
                        setNavIndex(idx);
                        setSlideIndex(idx)
                    } }
                    renderCenterLeftControls={() => null}
                    renderCenterRightControls={() => null}
                    swiping
                    vertical
                    slidesToShow={4}
                    slidesToScroll={1}
                    heightMode="first"
                    autoGenerateStyleTag
                >
                    {
                        pictures.map( (el, i) => (
                            <img
                                key={i}
                                onClick={ () => setSlideIndex(i) }
                                src={el}
                                onLoad={() => handleLoadSize('nav')}
                            />
                        ) )
                    }
                </Carousel>
            </div>
            <div className="slider_outer">
                <Carousel
                    ref={c => carousel = c}
                    slideIndex={ slideIndex }
                    afterSlide={ (slideIndex) => {
                        setSlideIndex(slideIndex);
                        setNavIndex(slideIndex)
                    } }
                    renderCenterLeftControls={() => null}
                    renderCenterRightControls={() => null}
                    swiping
                    heightMode="max"
                    autoGenerateStyleTag
                >
                    {
                        pictures.map((el, i) => (
                            <img src={el} key={`img_sldr_${i}`} className="inner_slider_image" alt="" onLoad={() => handleLoadSize('carousel')} />
                        ))
                    }
                </Carousel>
            </div>
        </div>
    );
}

export default ProductSlider;