import React from 'react';

const ContattiMap = () => {
    return (
        <div className="contatti_map">
            <iframe 
                className="map_iframe" 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11141.330876432385!2d9.073208!3d45.7244219!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3748496ac629dbb7!2sApicoltura%20Costenaro%20G%20%26%20C%20Snc!5e0!3m2!1sit!2sit!4v1574932424481!5m2!1sit!2sit" 
                frameBorder="0" 
                style={{border: '0'}}
                title="Google Maps Position" 
                allowFullScreen="">
            </iframe>
        </div>
    );
}

export default ContattiMap;