import React from 'react';
import {Link} from 'react-router-dom';

const Button = ({text, to, style}) => {
    return (
        <Link to={to} className="custom_btn opSans" style={style}>
            {text}
        </Link>
    );
}

export default Button;