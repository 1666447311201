import React from 'react';
import Product from './Product';

const Category = ({ name, products }) => {
    return (
        <div className="category">
            <h2 className="category_title poppins">{name}</h2>
            <div className="category_products">
                {
                    products.map((el, i) => (
                        <Product 
                            key={`product-${i}`} 
                            title={el.title}
                            description={el.description}
                            image={el.image}
                            imageAlt={el.imageAlt}
                            id={el.id}
                        />
                    ))
                }
            </div>
        </div>
    );
}

export default Category;