import React from 'react';
import {FaFacebookF, FaInstagram} from 'react-icons/fa';
import { MdArrowUpward } from 'react-icons/md';
import animateScrollTo from 'animated-scroll-to';

const Footer = () => {
    return (
        <footer>
            <div className="container flexed" style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
                <div className="left_footer">
                    <p className="opSans">
                        Apicoltura Costenaro di Costenaro Giancarlo {'&'} C Snc <br />
                        P.I. 02041550134 <br />
                        &copy; 2019 Fullstackdev.web, All Rights Reserved.
                    </p>
                </div>
                <div className="right_footer">
                    <a href="https://www.facebook.com/Apicoltura-Costenaro-394134347320725" className="social_icon">
                        <FaFacebookF fill="#fff" className="social_inner" />
                        <FaFacebookF fill="#fff" className="social_inner" />
                    </a>
                    <a href="https://www.instagram.com/apicoltura_costenaro" className="social_icon">
                        <FaInstagram fill="#fff" className="social_inner" />
                        <FaInstagram fill="#fff" className="social_inner" />
                    </a>     
                </div>
            </div>
            <div className="to_top_arrow" onClick={() => animateScrollTo(0)}>
                <MdArrowUpward className="to_top" />
            </div>
        </footer>
    )
}

export default Footer;