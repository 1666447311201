import React from 'react';

const style = {
    container: {
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        backgroundColor: 'rgba(255,255,255, 0.6)',
        zIndex: '10000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
    },
    innerContainer: {
        width: '50%',
        margin: 'auto',
        position: 'relative',
    },
    dot: {
        width: '10px',
        height: '10px',
        backgroundColor: '#000',
        borderRadius: '50%',
        display: 'inline-block',
        animationName: 'slide',
        animationDuration: '2s',
        animationTimingFunction: 'ease',
        animationIterationCount: 'infinite',
        willChange: 'transform, opacity',
        opacity: '0',
    },
    dot_2: {
        animationDelay: '.33s',
    },
    dot_3: {
        animationDelay: '.66s',
    },
    dot_4: {
        animationDelay: '.99s',
    }
}

const Loading = ({ showing }) => {
    return showing ? (
        <div 
            className="loading_animation" 
            style={style.container}
        >
            <div className="dots_container" style={style.innerContainer}>
                <div className="dot dot_1" style={{...style.dot, ...style.dot_1}}></div>
                <div className="dot dot_2" style={{...style.dot, ...style.dot_2}}></div>
                <div className="dot dot_3" style={{...style.dot, ...style.dot_3}}></div>
                <div className="dot dot_4" style={{...style.dot, ...style.dot_4}}></div>
            </div>
        </div>
    ) : null;
}

export default Loading;