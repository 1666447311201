import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const SkillBar = () => {
    return (
        <div className="skillbar">
            <div className="skill">
                <div className="skill_title poppins">
                    <FaQuoteLeft className="skill_icon" /> <br />
                    Albert Einstein
                </div>
                <div className="skill_text">
                    <p className="opSans lgrey">
                        Se le api scomparissero dalla faccia della terra, all’uomo non resterebbero che quattro anni di vita.<br/>
                    </p>
                </div>
                <div style={{display: "flex",justifyContent: "flex-end",width:"100%"}}><FaQuoteRight className="skill_icon" /></div>
            </div>
            <div className="skill">
                <div className="skill_title poppins">
                    <FaQuoteLeft className="skill_icon" /> <br />
                    Michel Onfray
                </div>
                <div className="skill_text">
                    <p className="opSans lgrey">
                        Ogni ape porta in sé il meccanismo dell’universo: ognuna riassume il segreto del mondo.<br/>
                    </p>
                </div>
                <div style={{display: "flex",justifyContent: "flex-end",width:"100%"}}><FaQuoteRight className="skill_icon" /></div>
            </div>
            <div className="skill">
                <div className="skill_title poppins">
                    <FaQuoteLeft className="skill_icon" /> <br />
                    Pef
                </div>
                <div className="skill_text">
                    <p className="opSans lgrey">
                        Ape: piccolo insetto capace di fabbricare il cielo. <br/>

                    </p>
                </div>
                <div style={{display: "flex",justifyContent: "flex-end",width:"100%"}}><FaQuoteRight className="skill_icon" /></div>
            </div>
        </div>
    );
}

export default SkillBar;