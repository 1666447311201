import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Prodotti from './components/prodotti/Prodotti';
import Prodotto from './components/prodotto/Prodotto';
import Footer from './components/Footer';
import Contatti from './components/contatti/Contatti';
import ChiSiamo from './components/chiSiamo/ChiSiamo';

const Navigation = props => {
    return (
        <Router>
            <Navbar />
            <div className="allApp">
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route exact path="/prodotti" component={Prodotti} />
                    <Route exact path="/prodotto/:id" component={Prodotto} />
                    <Route exact path="/contatti" component={Contatti} />
                    <Route exact path="/chisiamo" component={ChiSiamo} />
                </Switch>
            </div>
            <Footer />
        </Router>
    );
}

ReactDOM.render(<Navigation />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
