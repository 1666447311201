import React from 'react';
import {Helmet} from "react-helmet"
import a from '../../assets/chi_siamo_images/1-min.jpg'
import b from '../../assets/chi_siamo_images/2-min.jpg'
import c from '../../assets/chi_siamo_images/3-min.jpg'
import e from '../../assets/chi_siamo_images/5-min.jpg'
import f from '../../assets/chi_siamo_images/6-min.jpg'
import g from '../../assets/chi_siamo_images/7-min.jpg'
import h from '../../assets/chi_siamo_images/8.jpg'

const ChiSiamo = () => {
    const pics = [
        {
            src: g,
            nome: "Arrigo",
        },
        {
            src: c,
            nome: "Anna",
        },
        /*{
            src: b,
            nome: "Giancarlo",
        },*/
        {
            src: e,
            nome: "Mauro",
        },
        {
            src: f,
            nome: "Elena",
        },
        {
            src: a,
            nome: "Luca",
        },
        {
            src: h,
            nome: "Elia",
        },
    ]
    return (

        <div className="container" style={{paddingTop: '100px', paddingLeft: '15px', paddingRight: '15px'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="chi siamo apicoltura api miele como staff vertemate con minoprio costenaro apicoltura giancarlo costenaro " />
                <meta name = "description" content="Produciamo e vendiamo miele da decenni. Prodotti di qualita' e 100% locali. Scopri chi siamo!" />
                <title>Chi siamo | Apicoltura Costenaro</title>
            </Helmet>
            <div className="upperTitle" style={{paddingTop: '50px'}}>
                <h2 className="poppins chisiamo_title">Eccoci!</h2>
                <p className="chisiamo_short_desc opSans">
                    <strong>Apicoltori da decenni.</strong> <br />
                    Perch&egrave; siamo i migliori? Perch&egrave; amiamo quello che facciamo.
                </p>
                <div className="descr">
                    <img src={b} alt="" />
                    <p className="opSans">
                        Mi chiamo Giancarlo Costenaro e vivo a Vertemate con Minoprio, un piccolo paese situato tra la zona prealpina del lago di Como e l’inizio della pianura Padana.
                        Sono il contitolare dell’azienda agricola “Apicoltura Costenaro G&C SNC”. È una società a gestione famigliare nella quale sono coinvolte tre diverse generazioni;
                        tutt’oggi i miei figli ed io lavoriamo con i miei genitori traendo dalla loro esperienza decennale iniziata nel 1974 per trarne ispirazioni per il futuro.
                        I miei genitori intrapresero questa carriera quando avevo otto anni, ricordo come se fosse ieri il primo alveare … quanta paura mi incuteva. Questa nuova
                        realtà mi spaventava ma ben presto imparai a conviverci e ad apprezzarla in tutte le sue sfumature. Quando assaggia il primo miele che avevamo prodotto mi
                        sentii realizzato e appagato per tutto il nostro duro lavoro. Ben presto le api iniziarono ad affascinarmi e ad incuriosirmi. Mi stupivo di come un piccolo
                        insetto vivesse una vita così impegnativa e svolgesse un ruolo preciso e fondamentale all’interno di una società ben strutturata. Rimanevo per ore incantato
                        ad ammirare il loro volo preciso, coordinato, diretto verso le sorgenti di polline e nettare più vicine.
                        Crescendo, autonomamente, iniziai a studiare i metodi di produzione usati in apicoltura, la flora apistica e l’erboristeria. A ventidue anni diedi vita alla
                        mia azienda trasformando una piccola realtà  in una società vera e propria coinvolgendo i tutti i miei familiari.
                        Oggi contiamo più di un migliaio di alveari con i quali produciamo miele, polline, pappa reale, propoli ed altri derivati erboristici. Portiamo le nostre api
                        in zone incontaminate lontane dai centri urbani, per garantire loro una vita serena immersa nel loro habitat naturale. Questa situazione di benessere permettere
                        loro di produrre un miele di ottima qualità.
                        Perché è il nostro obbiettivo quello di portare sulle tavole dei nostri clienti miele e prodotti di prima qualità, freschi e
                        genuini. Così che aprendo i nostri vasetti possano immergersi nel loro profumo e sapore e riuscire ad immaginare gli inviolati e naturali territori di produzione.
                    </p>
                </div>
            </div>
            <div className="chisiamo_fotodiv container">
                {
                    pics.map((el, i) => (
                        <div className="person_container" key={`image-${i}`}>
                            <img src={el.src} className="chisiamo_image" alt="" />
                            <div className="chisiamo_caption">
                                <h6 className="poppins">{el.nome}</h6>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ChiSiamo;