import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Link } from 'react-router-dom';
import {MdMenu, MdClose} from 'react-icons/md';
import {myAnimate} from '../helpers/animating';
import logo from '../assets/logos/logo_bianco.png'

const Navbar = props => {
    const logoStyle = {
        width: "50px",
    };
    const [scrolled, setScroll] = useState(false);
    const [toggled, setToggled] = useState(false);
    const mobRef = useRef(null);

    const toggleNav = useCallback(
        () => {
            if (toggled) {
                myAnimate(mobRef.current, 20, el => {
                    el.setAttribute('style', 'display:flex !important');
                })
                .then(el => {
                    el.classList.add('nav_visible');
                })
                .catch(err => console.log(err));
            } else {
                myAnimate(mobRef.current, 210, el => {
                    el.classList.remove('nav_visible');
                })
                .then(el => el.setAttribute('style', 'display: none !important'))
                .catch(err => console.log(err));
            }
        },
        [toggled]
    );

    useEffect(
        () => {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 0) {
                    setScroll(true)
                } else {
                    setScroll(false)
                }
            });
            toggleNav();
        }, [toggleNav]
    );
    const linksArr = [
        {
            to: '/prodotti',
            text: 'Prodotti',
        },
        {
            to: '/chisiamo',
            text: 'Chi siamo',
        },
        {
            to: '/contatti',
            text: 'Contatti',
        },
    ];
    return (
        <nav className={`navbar ${scrolled ? `scrolled` : ``}`}>
            <div className={`container innerNav ${scrolled ? `innerScrolled` : ``}`}>
                <div className="logo">
                    <Link to="/" className="poppins bold lg">
                        <img src={logo} slt="" style={logoStyle} />
                    </Link>
                </div>
                <div className="toggler mob" onClick={() => setToggled(true)}>
                    <MdMenu className="toggler_icon" />
                </div>
                <div className="links desk">
                    <a href="https://artigianoinfiera.it/artigiani/52772-Apicoltura-Costenaro/prodotti" className="link poppins" target="_blank">Shop</a>
                    {
                        linksArr.map(el => (
                            <Link to={el.to} key={el.text} className="link poppins">
                                {el.text}
                            </Link>
                        ))
                    }
                    <a href="https://en.apicolturacostenaro.com/" className="link poppins">English</a>
                </div>
                <div className="mobile_links mob" ref={mobRef}>
                    <MdClose className="closer" onClick={() => setToggled(false)} />
                    <div className="mob_links_cnt">
                        <a href="https://artigianoinfiera.it/artigiani/52772-Apicoltura-Costenaro/prodotti" className="link poppins" target="_blank">Shop</a>
                        {
                            linksArr.map((el, i) => (
                                <Link to={el.to} onClick={() => setToggled(false)} key={`mob-link-${i}`} className="mob_link poppins">
                                    {el.text}
                                </Link>
                            ))
                        }
                        <a href="https://en.apicolturacostenaro.com/" className="mob_link poppins">English</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;