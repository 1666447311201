import React from 'react';
import{Helmet} from "react-helmet";
import { prods } from './prod_data';
import Category from './Category';

const Prodotti = props => {
    return (
        <div className="container">
            <div className="products">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="keywords" content="prodotti miele composto composto-dell-alveare miele-con-noci miele-con-nocciole sciroppo propoli propolis acacia millefiori castagno tiglio melata ciliegio eucalipto girasole arancio polline pappa-reale pappa reale cera cera-api lichene balsamel sciroppo-lichene sciroppo-balsamel apicoltura-costenaro  vertemate-con-minoprio vertemate con minoprio costenaro apicoltura giancarlo costenaro api " />
                    <meta name = "description" content="Produciamo e vendiamo miele da decenni. Prodotti di qualita' e 100% locali. Scopri i nostri prodotti!" />
                    <title>I nostri prodotti | Apicoltura Costenaro</title>
                </Helmet>
                {
                    prods.map((el, i) => (
                        <Category name={el.category} products={el.prods} key={`category-${i}`} />
                    ))
                }
            </div>
        </div>
    );
}

export default Prodotti;