import React, { useRef, useState } from 'react';
import bckg from '../assets/chi_siamo_images/cassetta-miele.jpg';
import vid from "../assets/home-video.mp4";
import logo from '../assets/logos/logo_scritta_bianco.png'
import { MdVolumeUp, MdVolumeMute } from "react-icons/md"
import {ParallaxBanner} from 'react-scroll-parallax';

const Header = ({mobile}) => {
    const logoStyle = {
        width: "100%",
    };
    const [muted, setMuted] = useState(true);
    const videoRef = useRef();

    return (
        <>
            {/* {<ParallaxBanner
                className="header"
                layers={[
                    {
                        image: bckg,
                        amount: -0.4,
                    },
                ]}
                style={{
                    height: '65vh',
                }}
                disabled={mobile}
            >
                <h1 className="big_title poppins">
                    <img src={logo} alt='logo' style={logoStyle}  />
                </h1>
            </ParallaxBanner>} */}
            <div
                style={{ width: '100%', position: 'relative' }}
            >
                <button
                    onClick={() => setMuted(m => !m)}
                    className="mute_btn"
                >
                    {
                        muted ?
                        <MdVolumeMute /> :
                        <MdVolumeUp />
                    }
                </button>
                <video
                    preload="none"
                    poster={bckg}
                    controls={false}
                    autoPlay
                    muted={muted}
                    loop
                    ref={videoRef}
                >
                    <source src={vid} type="video/mp4" />
                </video>
            </div>
        </>
    );
}

export default Header;